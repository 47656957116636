<template>
    <div class="w-100">
        <div :class="stack ? '' : 'd-flex'" :style="'gap: ' + gap" class="datePickerTheme timePickerTheme">
            <b-datepicker v-model="string.date" :size="size" :min="min" :max="max"
                          :style="'flex: ' + dateFlex + '; ' + (redBorder || !string.date ? 'border: 1px solid #f86c6b' : '')"/>
            <div v-if="stack" :style="'height: ' + gap"/>
            <b-timepicker v-model="string.time" :size="size" v-if="!dateOnly"
                          :style="'flex: ' + timeFlex + '; ' + (redBorder || !string.time ? 'border: 1px solid #f86c6b' : '')"/>
        </div>
    </div>
</template>

<script>
export default {
    name: "MDateTimePicker",
    props: {
        value: {
            type: Date
        },
        dateFlex: {
            type: Number,
            default: 1
        },
        timeFlex: {
            type: Number,
            default: 1
        },
        size: {
            type: String
        },
        stack: {
            type: Boolean,
            default: true
        },
        gap: {
            type: String,
            default: '3px'
        },
        min: {
            type: Date
        },
        max: {
            type: Date
        },
        redBorder: {
            type: Boolean
        },
        dateOnly: {
            type: Boolean,
            default: false
        }
    },
    data: () => {
        return {
            string: {
                date: null,
                time: null
            },
            unix: {
                date: null,
                time: null
            },
        }
    },
    methods: {
        setStringDateTime() {
            if (this.value) {
                let string = {
                    date: this.value.toISOString().split('T')[0],
                    time: this.value.toISOString().split('T')[1].substring(0, 8)
                };
                if (string.date !== this.string.date) {
                    this.$set(this.string, 'date', string.date);
                }
                if (string.time !== this.string.time) {
                    this.$set(this.string, 'time', string.time);
                }
            }
        },
        setUnixDateTime() {
            if (this.string.date) {
                let unixDate = new Date(this.string.date);
                if (unixDate !== this.unix.date) {
                    this.$set(this.unix, 'date', unixDate);
                }
            }
            if (this.string.time) {
                let unixTime = new Date(`1970-01-01T${this.string.time}Z`);
                if (unixTime !== this.unix.time) {
                    this.$set(this.unix, 'time', unixTime);
                }
            }
        },
        combineDateTime() {
            if (this.unix.date && this.unix.time) {
                let result = new Date(this.unix.date.getTime() + this.unix.time.getTime());
                if (this.value !== result) {
                    this.$emit('input', result);
                    this.$emit('change');
                }
            }
        }
    },
    watch: {
        value: {
            immediate: true,
            handler(newVal, oldVal) {
                if (newVal && (!oldVal || newVal.getTime() !== oldVal.getTime())) {
                    this.setStringDateTime();
                }
            }
        },
        string: {
            deep: true,
            handler() {
                this.setUnixDateTime();
            }
        },
        unix: {
            deep: true,
            handler() {
                this.combineDateTime();
            }
        }
    }
}
</script>