<template>
    <div>

        <!-- Create Modal -->
        <b-modal v-model="createModal.bool" v-if="createModal.bool" title="Create Stream Share" class="modal-dark" no-close-on-backdrop
                 no-close-on-esc scrollable>

            <!-- Description -->
            <div>
                <div class="mFieldsetTitle d-flex justify-content-center align-items-center" style="gap: 6px">
                    <div>Description</div>
                </div>
                <div class="mFieldset">
                    <div style="margin-top: 6px">
                        <b-input v-model="createModal.data.desc" size="sm" :maxlength="createModal.descCharLimit"/>
                    </div>
                    <div style="font-size: 10px; margin-bottom: -10px">
                        {{createModal.data.desc.length}} / {{createModal.descCharLimit}}
                    </div>
                </div>
            </div>

            <!-- Recipients' E-Mail -->
            <div>
                <div class="mFieldsetTitle d-flex justify-content-center align-items-center" style="gap: 6px">
                    <div>Recipients' E-Mail</div>
                    <b-badge variant="success" style="height: fit-content; font-size: 11px; cursor: pointer"
                             @click="addRecipient()">
                        <i class="fa fa-plus"/> Add
                    </b-badge>
                </div>
                <div class="mFieldset">
                    <div style="margin-top: 6px">
                        <div v-for="(email, index) in createModal.data.recipientsEmail" :key="'recipientEmail' + index" style="margin-bottom: 3px">
                            <b-input-group>
                                <b-form-input size="sm" v-model="createModal.data.recipientsEmail[index]" @input="isFilledOut"
                                              :style="!isEmail(email) ? 'border: 1px solid #f86c6b' : ''"/>
                                <b-input-group-append size="sm" @click="deleteRecipient(index)" style="cursor: pointer" v-if="email || createModal.data.recipientsEmail.length > 1">
                                    <b-input-group-text style="background-color: #f86c6b" class="d-flex justify-content-center align-items-center">
                                        <i class="fa fa-close"/>
                                    </b-input-group-text>
                                </b-input-group-append>
                            </b-input-group>
                            <div v-if="email && !isEmail(email)" style="color: #f86c6b; font-size: 10px">
                                email is not valid
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Date Range -->
            <div>
                <div class="mFieldsetTitle d-flex justify-content-center align-items-center" style="gap: 6px">
                    <div>Time Shared</div>
                </div>
                <div class="mFieldset">

                    <!-- Type Date Range Select -->
                    <b-radio-group v-model="createModal.data.timeSelection" @change="isFilledOut"
                                   :options="['Duration', 'Date Range', 'Until Date']"/>

                    <!-- Duration Time Selection -->
                    <div v-if="createModal.data.timeSelection === 'Duration'" style="margin-top: 6px">
                        <b>Duration:</b>
                        <b-select :options="createModal.timeSelectionOptions" v-model="createModal.data.duration"
                                  :style="!createModal.data.duration ? 'border: 1px solid #f86c6b' : ''"
                                  @change="isFilledOut" size="sm"/>
                    </div>

                    <!-- Date Range Time Selection -->
                    <div v-if="createModal.data.timeSelection === 'Date Range'"
                         style="margin-top: 6px; gap: 6px">
                        <div style="flex: 1">
                            <b>Start Date/Time:</b>
                            <MDateTimePicker v-model="createModal.data.startDate" :min="createModal.todaysDate"
                                             size="sm" :stack="false" @change="isFilledOut"
                                            :red-border="!createModal.data.startDate
                                                    || createModal.todaysDate >= createModal.data.startDate
                                                    || (
                                                        createModal.data.endDate
                                                        && createModal.data.startDate >= createModal.data.endDate
                                                    )"/>
                            <div v-if="createModal.data.startDate && createModal.todaysDate > createModal.data.startDate"
                                 style="font-size: 10px; color: #f86c6b">
                                Can't set date in the past
                            </div>
                            <div v-else-if="!createModal.data.startDate || !createModal.data.endDate"/>
                            <div v-else-if="createModal.data.startDate >= createModal.data.endDate"
                                 style="font-size: 10px; color: #f86c6b">
                                Cant set date/time after end date/time
                            </div>
                        </div>
                        <div style="flex: 1">
                            <b>End Date/Time:</b>
                            <MDateTimePicker v-model="createModal.data.endDate" :min="createModal.todaysDate"
                                             size="sm" :stack="false" @change="isFilledOut"
                                             :red-border="!createModal.data.endDate
                                                    || createModal.todaysDate >= createModal.data.endDate
                                                    || (
                                                        createModal.data.startDate
                                                        && createModal.data.startDate >= createModal.data.endDate
                                                    )"/>
                            <div v-if="createModal.data.endDate && createModal.todaysDate > createModal.data.endDate"
                                 style="font-size: 10px; color: #f86c6b">
                                Can't set date in the past
                            </div>
                            <div v-else-if="!createModal.data.startDate || !createModal.data.endDate"/>
                            <div v-else-if="createModal.data.startDate >= createModal.data.endDate"
                                 style="font-size: 10px; color: #f86c6b">
                                Cant set date/time before start date/time
                            </div>
                        </div>

                    </div>

                    <!-- Until Date Time Selection -->
                    <div v-if="createModal.data.timeSelection === 'Until Date'" style="margin-top: 6px">
                        <b>Share Until:</b>
                        <MDateTimePicker v-model="createModal.data.untilDate" :min="createModal.todaysDate"
                                         size="sm" :stack="false" @change="isFilledOut"
                                        :red-border="!createModal.data.untilDate
                                                || createModal.todaysDate >= createModal.data.untilDate"/>
                        <div v-if="createModal.data.untilDate && createModal.todaysDate > createModal.data.untilDate"
                             style="font-size: 10px; margin-bottom: -10px; color: #f86c6b">
                            Can't set date in the past
                        </div>
                    </div>

                </div>
            </div>

            <!-- Device Tree -->
            <div>
                <div class="mFieldsetTitle d-flex justify-content-center align-items-center" style="gap: 6px">
                    <div>Devices</div>
                </div>
                <div class="mFieldset">
                    <!-- Tenants -->
                    <div v-for="tenant in tenants" :key="'createStreamShareConst-' + tenant._id"
                         style="height: 400px; overflow-x: auto; margin-right: -7px">
                        <div class="d-flex align-items-center" style="gap: 3px">
                            <fa-icon :icon="['fas', 'folder-open']"/>
                            {{tenant._id}}
                        </div>
                        <!-- Hubs -->
                        <div v-for="(hub, hubname, hubIndex) in tenant.hubs" :key="'createStreamShareHub-' + hubname"
                             style="padding-left: 6px">
                            <div class="d-flex align-items-center" style="gap: 3px; height: 17px">
                                <div class="d-flex align-items-center" style="padding-right: 3px">
                                    <div v-if="hubIndex + 1 < Object.keys(tenant.hubs).length"
                                         style="border-right: 1px solid white; height: 17px"/>
                                    <div v-else style="height: 17px">
                                        <div style="border-right: 1px solid white; height: 9px"/>
                                    </div>
                                    <div style="border-top: 1px solid white; width: 10px"/>
                                </div>

                                <fa-icon :icon="['fas', 'folder-open']"/>
                                {{hubname}}
                            </div>
                            <!-- Devices -->
                            <div v-for="(device, deviceIndex) in hub" :key="'createStreamShareDevice-' + device.getDeviceId()">
                                <div>
                                    <div class="d-flex align-items-center" style="height: 17px">
                                        <div class="d-flex align-items-center" style="height: 17px; padding-right: 6px">
                                            <div v-if="hubIndex + 1 < Object.keys(tenant.hubs).length"
                                                 style="border-right: 1px solid white; height: 17px; margin-right: 20px"/>
                                            <div v-else style="height: 17px; margin-right: 20px"/>
                                            <div v-if="deviceIndex + 1 < hub.length"
                                                 style="border-right: 1px solid white; height: 17px"/>
                                            <div v-else style="height: 17px">
                                                <div style="border-right: 1px solid white; height: 9px"/>
                                            </div>
                                            <div style="border-top: 1px solid white; width: 10px"/>
                                        </div>
                                        <div v-if="device.getMultiSensor() > 1 || device.getDeviceModel().includes('4308')"
                                             class="d-flex align-items-center" style="gap: 3px">
                                            <fa-icon :icon="['fas', 'folder-open']"/>
                                            {{device.getDeviceName()}}
                                        </div>
                                        <b-checkbox v-else class="mr-0" v-model="createModal.data.streams" @change="isFilledOut"
                                                    :value="{id: device.getDeviceId(), sourceToken: device.getFirstSourceToken()}">
                                            {{device.getDeviceName()}}
                                        </b-checkbox>
                                    </div>
                                    <!-- Multi-Sensors -->
                                    <div v-if="device.getMultiSensor() > 1 || device.getDeviceModel().includes('4308')">
                                        <div v-for="(sensor, sensorIndex) in device.getMultiSensor() > 1 ? device.getMultiSensorStreams() : device.getSingleSensorStreams()"
                                             class="d-flex align-items-center" style="height: 17px" :key="'createStreamShareSensors-' + sensor.friendlyName">
                                            <div class="d-flex align-items-center" style="height: 17px; padding-right: 6px">
                                                <div v-if="hubIndex + 1 < Object.keys(tenant.hubs).length"
                                                     style="border-right: 1px solid white; height: 17px; margin-right: 20px"/>
                                                <div v-else style="height: 17px; margin-right: 20px"/>
                                                <div v-if="deviceIndex + 1 < hub.length"
                                                     style="border-right: 1px solid white; height: 17px; margin-right: 20px"/>
                                                <div v-else style="height: 17px; margin-right: 20px"/>
                                                <div v-if="sensorIndex + 1 < (device.getMultiSensor() > 1 ? device.getMultiSensorStreams() : device.getSingleSensorStreams()).length"
                                                     style="border-right: 1px solid white; height: 17px"/>
                                                <div v-else style="height: 17px">
                                                    <div style="border-right: 1px solid white; height: 9px"/>
                                                </div>
                                                <div style="border-top: 1px solid white; width: 10px"/>
                                            </div>
                                            <b-checkbox class="mr-0" v-model="createModal.data.streams" @change="isFilledOut"
                                                        :value="{id: device.getDeviceId(), sourceToken: sensor.sourceToken}">
                                                {{sensor.friendlyName}}
                                            </b-checkbox>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <!-- FOOTER -->
            <template slot="modal-footer">
                <b-button size="sm" variant="secondary" @click="createModal.bool = false">
                    Cancel
                </b-button>
                <b-button size="sm" variant="primary" @click="submitCreateModal()" :disabled="!createModal.isFilledOut">
                    Submit
                </b-button>
            </template>

        </b-modal>

        <!-- Info Modal -->
        <b-modal v-model="infoModal.bool" v-if="infoModal.bool" title="Create Stream Share" class="modal-dark" no-close-on-backdrop
                 no-close-on-esc scrollable>

            <!-- Body -->
            <div class="d-flex flex-column" style="gap: 6px">

                <!-- Description -->
                <div class="d-flex" style="gap: 6px">
                    <TextValueBox text="Created By" :value="streamSharings[infoModal.index].createdBy" style="flex: 1"/>
                    <TextValueBox text="Created Date/Time" style="flex: 1"
                                  :value="new Date(streamSharings[infoModal.index].createdDate).toLocaleString()"/>
                </div>

                <!-- Description -->
                <TextValueBox text="Description" :value="streamSharings[infoModal.index].desc || '-'"/>

                <!-- Recipients' E-Mail -->

                <!-- Date Range -->
                <div class="d-flex" style="gap: 6px">
                    <TextValueBox text="Start Share Date/Time" style="flex: 1"
                                  :value="new Date(streamSharings[infoModal.index].startDate).toLocaleString()"/>
                    <TextValueBox text="End Share Date/Time" style="flex: 1"
                                  :value="new Date(streamSharings[infoModal.index].endDate).toLocaleString()"/>
                </div>

                <!-- Devices -->
                <div class="d-flex" style="gap: 6px">
                    <TextValueBox text="Recipients' E-Mail" style="flex: 1">
                        <span class="d-flex w-100 justify-content-center" style="white-space: pre-line; text-align: left">
                            <span>{{listArrElements(streamSharings[infoModal.index].recipientsEmail)}}</span>
                        </span>
                    </TextValueBox>
                    <TextValueBox text="Devices" style="flex: 1">
                        <span class="d-flex w-100 justify-content-center" style="white-space: pre-line; text-align: left">
                            <span>{{listArrElements(streamSharings[infoModal.index].streams)}}</span>
                        </span>
                    </TextValueBox>
                </div>

            </div>

            <!-- FOOTER -->
            <template slot="modal-footer">
                <div class="d-flex w-100 justify-content-between">
                    <div class="d-flex" style="gap: 6px">
                        <b-button v-if="!streamSharings[infoModal.index].revoked" size="sm" variant="info"
                                  @click="playPauseShare(infoModal.index)">
                            {{streamSharings[infoModal.index].paused ? 'Resume' : 'Pause'}}
                        </b-button>
                        <b-button v-if="!streamSharings[infoModal.index].revoked" size="sm" variant="danger"
                                  @click="revokeShare(infoModal.index)">
                            Revoke
                        </b-button>
                    </div>
                    <b-button size="sm" variant="primary" @click="infoModal.bool = false">
                        Close
                    </b-button>
                </div>
            </template>

        </b-modal>
        <!-- Not Started, Active, Paused, Revoked, Expired -->

        <!-- TABLE -->
        <b-card style="width: 100%; height: 100%;">

            <!-- HEADER -->
            <div slot="header">
                <div class="d-flex w-100 justify-content-between">
                    <div class="d-flex align-items-center">
                        <h5 style="margin-bottom: 0"><b>Stream Sharing</b></h5>
                    </div>
                    <div class="d-flex align-items-center" style="gap: 8px">
                        <i class="fa fa-plus-square fa-lg" @click="openCreateModal()"/>
                    </div>
                </div>
            </div>

            <!-- TABLE -->
            <v-client-table ref="dTable" style="width: 100%" :columns="table.columns" :data="streamSharings" class="dataTable"
                            :options="table.options" :theme="table.theme">

                <!-- INFO/EDIT COLUMN -->
                <div slot="h__Extend/Revoke" style="float: right">Info</div>
                <div slot="Extend/Revoke" slot-scope="props"
                     style="margin: -12px; display: flex; justify-content: flex-end; gap: 5px; transform: translateY(6px)">
                    <b-button variant="dark" size="sm" class="iconButton" title="Edit" @click="openInfoModal(props.index - 1)">
                        <i class="fa fa-info fa-lg"/>
                    </b-button>
                </div>

                <div slot="recipientsEmail" slot-scope="props">
                    <div v-if="props.row.recipientsEmail.length > 1" style="cursor: context-menu"
                         :title="listArrElements(props.row.recipientsEmail)">
                        {{props.row.recipientsEmail[0] + ' ...'}}
                    </div>
                    <div v-else>
                        {{props.row.recipientsEmail[0]}}
                    </div>
                </div>

                <div slot="status" slot-scope="props">
                    <b-badge :variant="getStatus(props.row).variant">
                        {{getStatus(props.row).text}}
                    </b-badge>
                </div>

                <div slot="startDate" slot-scope="props">
                    {{new Date(props.row.startDate).toLocaleString()}}
                </div>

                <div slot="endDate" slot-scope="props">
                    {{new Date(props.row.endDate).toLocaleString()}}
                </div>

                <div slot="createdDate" slot-scope="props">
                    {{new Date(props.row.createdDate).toLocaleString()}}
                </div>

                <div slot="streams" slot-scope="props" :title="listArrElements(props.row.streams)"
                     style="cursor: context-menu">

                    <div v-if="props.row.streams.length > 1" style="cursor: context-menu"
                         :title="listArrElements(props.row.streams)">
                        {{props.row.streams[0].id + '-' + props.row.streams[0].sourceToken + ' ...'}}
                    </div>
                    <div v-else>
                        {{props.row.streams[0].id + '-' + props.row.streams[0].sourceToken}}
                    </div>
                </div>

            </v-client-table>

        </b-card>

    </div>
</template>

<script>
import Vue from 'vue';
import MDateTimePicker from '@/components/MDateTimePicker';
import {ClientTable} from 'vue-tables-2';
import tenantStore from "@/store/tenantStore";
import deviceStore from "@/store/deviceStore";
import PubSub from "pubsub-js";
import DateTimePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import TextValueBox from "@/components/TextValueBox.vue";

Vue.use(ClientTable);

export default {
    name: 'StreamSharing',
    components: {
        TextValueBox,
        DateTimePicker,
        MDateTimePicker
    },
    data: () => {
        return {
            testDate: null,
            tenants: [],
            infoModal: {
                bool: false,
                index: null
            },
            createModal: {
                bool: false,
                todaysDate: null,
                isFilledOut: false,
                descCharLimit: 30,
                data: null,
                dataTemplate: {
                    timeSelection: 'Duration',
                    untilDate: null,
                    duration: null,
                    desc: '',
                    recipientsEmail: [],
                    startDate: null,
                    endDate: null,
                    streams: []
                },
                timeSelectionOptions: [
                    {value: 3600*1000, text: '1 hour'},
                    {value: 3600*1000*3, text: '3 hours'},
                    {value: 3600*1000*6, text: '6 hours'},
                    {value: 3600*1000*12, text: '12 hours'},
                    {value: 3600*1000*24, text: '1 day'},
                    {value: 3600*1000*48, text: '2 days'},
                    {value: 3600*1000*72, text: '3 days'},
                    {value: 3600*1000*24*7, text: '1 week'}
                ],
            },
            streamSharings: [],
            table: {
                columns: ['createdBy', 'desc', 'recipientsEmail', 'status', 'startDate', 'endDate', 'createdDate', 'streams', 'Extend/Revoke'],
                options: {
                    orderBy: {column: 'user', ascending: false},
                    headings: {
                        createdBy: 'CreatedBy',
                        desc: 'Description',
                        recipientsEmail: 'Recipients\' Email',
                        status: 'Status',
                        startDate: 'Start Date',
                        endDate: 'End Date',
                        createdDate: 'Created Date',
                        streams: 'Streams'
                    },
                    sortable: ['createdBy', 'desc', 'startDate', 'endDate', 'createdOn'],
                    filterable: ['createdBy', 'desc', 'recipientsEmail', 'startDate', 'endDate', 'createdOn', 'streams'],
                    sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                    pagination: {
                        chunk: 5,
                        edge: false,
                        nav: 'scroll'
                    },
                    perPage: 25,
                    skin: 'table table-striped table-hover',
                },
                theme: 'bootstrap4',
            },
            pubsubs: []
        }
    },
    async created() {
        this.streamSharings = JSON.parse(localStorage.getItem('streamSharings')) || [];
        this.tenants.push(...(await tenantStore.dispatch('getTenants').catch(error => {
            console.error(error);
            this.$mToast({
                title: error.response.status + ' Error',
                text: "Tenant couldn't be retrieved: " + error.response.statusText,
                style: 'error'
            });
        })));
        this.tenants.forEach((tenant, index) => {
            deviceStore.dispatch('getSmartSuiteDevices', tenant._id).then(devices => {
                this.updateDevices(index, devices)
            });
            this.pubsubs.push(PubSub.subscribe('ssdevicesupdate-' + tenant._id, (msg, devices) => {
                this.updateDevices(index, devices)
            }));
        });
    },
    beforeDestroy() {
        this.pubsubs.forEach(sub => {
            PubSub.unsubscribe(sub);
        });
    },
    methods: {
        revokeShare(index) {
            this.$set(this.streamSharings[index], 'revoked', true);
            localStorage.setItem("streamSharings", JSON.stringify(this.streamSharings));
        },
        playPauseShare(index) {
            this.$set(this.streamSharings[index], 'paused', !this.streamSharings[index].paused);
            localStorage.setItem("streamSharings", JSON.stringify(this.streamSharings));
        },
        getStatus(obj) {
            let now = new Date();
            let startDate = new Date(obj.startDate);
            let endDate = new Date(obj.endDate);
            if (obj.revoked) {
                return {text: 'Revoked', variant: 'dark'};
            } else if (obj.paused) {
                return {text: 'Paused', variant: 'warning'};
            } else if (now < startDate) {
                return {text: 'Queued', variant: 'info'};
            } else if (now < endDate) {
                return {text: 'Active', variant: 'success'};
            } else {
                return {text: 'Expired', variant: 'danger'};
            }
        },
        listArrElements(arr) {
            return arr.reduce((str, _, index) => {
                if (index !== 0) {
                    str += '\n';
                }
                str += (index + 1) + '. '
                if (typeof _ === 'object') {
                    return str + _.id + '-' + _.sourceToken
                } else {
                    return str + _
                }
            }, '')
        },
        submitCreateModal() {
            this.createModal.bool = false;
            let data = this.createModal.data;
            switch (data.timeSelection) {
                case 'Duration':
                    data.startDate = new Date();
                    data.endDate = new Date(Date.now() + data.duration);
                    break;
                case 'Until Date':
                    data.startDate = this.createModal.todaysDate;
                    data.endDate = data.untilDate;
            }
            let payload = {
                desc: data.desc,
                recipientsEmail: data.recipientsEmail,
                startDate: data.startDate,
                endDate: data.endDate,
                streams: data.streams,
                createdBy: 'DGalluzzo',
                createdDate: new Date(),
                paused: false,
                revoked: false
            };
            this.streamSharings.push(payload);
            localStorage.setItem("streamSharings", JSON.stringify(this.streamSharings));
        },
        updateDevices(index, devices) {
            if (devices && Array.isArray(devices) && devices.length > 0 && !this.createModal.bool) {
                if (this.tenants[index].hubs === undefined) {
                    this.$set(this.tenants[index], 'hubs', {});
                }
                let hubCount = {};
                devices.forEach(device => {
                    if (device.getStreams().length > 0) {
                        let hubName = device.getHubName();
                        if (!this.tenants[index].hubs[hubName]) {
                            Vue.set(this.tenants[index].hubs, hubName, []);
                        }
                        if (hubCount[hubName] === undefined) {
                            hubCount[hubName] = 0;
                        }
                        this.$set(this.tenants[index].hubs[hubName], hubCount[hubName]++, device);
                    }
                });
                Object.keys(this.tenants[index].hubs).forEach(hub => {
                    if (hubCount[hub]) {
                        this.tenants[index].hubs[hub].splice(hubCount[hub] + 2);
                    }
                });
            }
        },
        openCreateModal() {
            this.$set(this.createModal, 'data', {});
            let template = JSON.parse(JSON.stringify(this.createModal.dataTemplate));
            Object.keys(template).forEach(key => {
                this.$set(this.createModal.data, key, template[key]);
            });
            this.addRecipient();
            this.$set(this.createModal, 'todaysDate', new Date());
            this.createModal.bool = true;
        },
        openInfoModal(index) {
            this.infoModal.index = index;
            this.infoModal.bool = true;
        },
        addRecipient() {
            let eMails = this.createModal.data.recipientsEmail;
            this.$set(eMails, eMails.length, '');
        },
        deleteRecipient(index) {
            let eMails = this.createModal.data.recipientsEmail;
            eMails.splice(index, 1);
            if (eMails.length === 0) {
                this.addRecipient();
            }
        },
        isEmail(email) {
            return email && (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/).test(email);
        },
        isFilledOut() {
            if (this.createModal.bool) {
                let data = this.createModal.data;
                if (data.streams.length > 0 && data.recipientsEmail.every(_ => this.isEmail(_))) {
                    switch (data.timeSelection) {
                        case 'Duration':
                            this.createModal.isFilledOut = !!data.duration;
                            break;
                        case 'Date Range':
                            this.createModal.isFilledOut = (
                                data.startDate
                                && data.endDate
                                && this.createModal.todaysDate < data.startDate
                                && data.startDate < data.endDate
                            );
                            break;
                        case 'Until Date':
                            this.createModal.isFilledOut = (
                                data.untilDate
                                && this.createModal.todaysDate < data.untilDate
                            );
                    }
                } else {
                    this.createModal.isFilledOut = false;
                }
            }
        }
    }

}

</script>

<style scoped>
.iconButton {
    height: 29px !important;
    width: 29px !important;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.iconButton:not(:hover) {
    background-color: #131313 !important;
}
.mFieldsetTitle {
    background-color: #1a1a1a;
    width: fit-content;
    transform: translate(5px, -12px);
    margin: 0;
    position: absolute;
    padding: 0 5px;
    display: block;
    font-size: 1.09375rem;
    font-weight: bolder;
}
.mFieldset {
    border-radius: 5px;
    border: 2px groove rgb(192, 192, 192);
    padding: 12px 10px 10px 10px;
    margin-top:  15px;
}
</style>